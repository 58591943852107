import React, { useState } from 'react';
import GenericContentDisplay from '../../components/GenericContentDisplay/GenericContentDisplay.components';

import { Link } from 'react-router-dom';
import "./ProgramPage.styles.scss";

const ProgramPage = () => {
  const [selectedMenu, setSelectedMenu] = useState("Basket Program")
  return (
    <div className="AboutPageContainer">
      <div className="MenuContainer">
        {/*<div className="DesignBox">hello</div>*/}
        <div className="Menus">
          <div onClick={()=>setSelectedMenu("Basket Program")} className={`${selectedMenu === "Basket Program" ? "Menu MenuBox1 MenuActive SpecialMarginBottom" : "Menu MenuBox1 SpecialMarginBottom"}`}>
            <i class="fas fa-shopping-basket"></i>
            <p className="text">Our basket program</p>
          </div>
          <div onClick={()=>setSelectedMenu("After Birth")} className={`${selectedMenu === "After Birth" ? "Menu MenuBox1 MenuActive" : "Menu MenuBox1"}`}>
            <i class="fas fa-child"></i>
            <p className="text">After Birth</p>
          </div>
          <div onClick={()=>setSelectedMenu("Our Community")} className={`${selectedMenu === "Our Community" ? "Menu MenuBox2 MenuActive" : "Menu MenuBox2"}`}>
            <i class="fas fa-users"></i>
            <p className="text">Our Community</p>
          </div>
          <div onClick={()=>setSelectedMenu("Sign Up")} className={`${selectedMenu === "Sign Up" ? "Menu MenuBox1 MenuActive" : "Menu MenuBox3"}`}>
            <i class="fas fa-file-alt"></i>
            <p className="text">Sign Up</p>
          </div>
        </div>
      </div>
      {selectedMenu === "Basket Program" &&
        <GenericContentDisplay image="./img/OurMission.jpeg" title="Our life basket program">
          <p className="text">
          The Life Basket Program targets low income and vulnerable pregnant women who require support to promote healthy and low stress pregnancies.<br/><br/>
          We will provide pregnant beneficiaries with simple supplemental resources (informational, material) in the form of a gift basket. By offering material items that may not otherwise be purchased, we aim to reduce stress for women during pregnancy and promote a more healthful environment for infant and mother.<br/><br/>
          An expecting person will be able to register on our website to start receiving a life basket every trimester that will provide tailored information to the individual benefactor.<br/><br/>
          After completing the registration process and providing proof of pregnancy, an expecting woman will be eligible to receive a package that is personalized to the applicants: gestational period, age, previous pregnancies, social and dietary lifestyle, and household income.
          </p>
          {/*<div className="ceoContainer">
            <div className="ceoContainer">
              image
            </div>
            <div className="ceoContainer">
              <h3>Aslam Yehia</h3>
              <p>CEO of rudiana foundation</p>
            </div>
          </div>*/}
        </GenericContentDisplay>
      }
      {selectedMenu === "After Birth" &&
        <GenericContentDisplay image="./img/OurStory.jpeg" title="What happens after birth ?">
          <p>
            Rudaina Foundation will provide health-related support to women and families experiencing pregnancy related complications.<br/><br/>
            This support deals with reducing pregnancy related complication costs by providing financial assistance to women fighting risk factors like lack of antenatal care, pregnancy induced hypertension, prolonged second stages of labor, and delivery by use of instruments or emergency caesarian section.<br/><br/>
            Follow-up program participation will include two initiatives:<br/><br/>
            1. Our pregnancy Support Mobile App which will provide mobile tracking of perinatal health biomarkers.<br />
            2. Our online Community Support Network which will foster a supportive social network within which health professionals (e.g., physicians, nutritionists, birth support), mothers, and mothers-to-be can connect, share resources and stories.<br/><br/>
            While some of treatments are covered under provincial health care, Rudaina will ensure that any costs that exceed insurance coverage is covered on behalf of the applicant.
          </p>
        </GenericContentDisplay>
      }
      {selectedMenu === "Our Community" &&
        <GenericContentDisplay image="./img/OurStory.jpeg" title="Our Community">
          <p>
            By signing up, you get the chance to be part of a community that is nation wide. Expecting women, nutritionists, registered nurses, midwives, and health care practitioners are all part of the conversation. The support is there for whomever seeks it.
          </p>
        </GenericContentDisplay>
      }
      {selectedMenu === "Sign Up" &&
        <GenericContentDisplay image="./img/OurTeam.jpeg" title="Become a benefactor !">
          <p>
            Every birth is different!.Sign up now to start receiving life basket tailored to your specific pregnancy.<br/><br/>
            An expecting person can start receiving a life basket every trimester, that will provide tailored unique information to each applicant.<br/><br/>
            By registering, the user is making a profile account on our website. This is done to streamline the process of how charitable organizations communicate with their benefactors. This process will also help us track if any problems occur during pregnancy.<br/><br/>
            You may qualify to receive our Life Baskets each trimester to promote health for yourself and your baby. Our priorities will be given to those who fall under any of the following conditions: In need of financial or community assistance, Part of a visible minority, Indigenous, Disabled, Under the age of 21, Single, New immigrant, Refugee.<br/><br/>
            Sign-up and join our program to qualify to receive our life baskets.
          </p>
          <Link to="sign-up" className="LinkButton">Go to sign up</Link>
        </GenericContentDisplay>
      }
    </div>
  );
}

export default ProgramPage;